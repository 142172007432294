@import "~vanilla-cookieconsent/dist/cookieconsent.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --mprimary-color: #ef433f;
  --black-color: #4d555a;
}

body {
  @apply bg-stone-100 dark:bg-stone-950;
}

.top-header {

  nav {
    @apply bg-stone-50 dark:bg-stone-950 flex items-center fixed w-full z-20 top-0 left-0 border-b border-stone-200 dark:border-stone-900;
    .logo {
      @apply relative w-[150px] md:w-[200px];

      margin-top: -60px;

      img {
        @apply absolute block;
        width: 100%;
      }
    ;
    }
  }
}

.btn-active {
  @apply hidden  md:block text-stone-50 bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800
}

.btn-icon {
  @apply inline-flex mr-2 items-center p-2 text-sm text-stone-600 rounded-full hover:bg-stone-100 focus:outline-none focus:ring-2 focus:ring-stone-200 dark:text-stone-400 dark:hover:bg-stone-800 dark:focus:ring-stone-600;
  svg {
    @apply w-6 h-6;
  }
}

.btn-collapse {
  @apply inline-flex items-center p-2 text-sm text-stone-500 rounded-lg md:hidden hover:bg-stone-100 focus:outline-none focus:ring-2 focus:ring-stone-200 dark:text-stone-400 dark:hover:bg-stone-700 dark:focus:ring-stone-600;
}

.top-menu {
  @apply items-center justify-between w-full md:flex md:w-auto md:order-1;
  ul {
    @apply flex flex-col p-4 md:p-0 mt-4 font-medium md:flex-row md:space-x-8 md:mt-0 md:border-0;
    li {
      a {
        @apply block py-2 pl-3 pr-4 text-stone-900 rounded hover:bg-stone-100 md:hover:bg-transparent md:hover:text-red-700 md:p-0 md:dark:hover:text-red-500 dark:text-white dark:hover:bg-stone-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-stone-700;
        &.active {
          @apply block py-2 pl-3 pr-4 text-white bg-red-500 rounded md:bg-transparent md:text-red-500 md:p-0 md:dark:text-red-500;
        }
      }
    }
  }
}

#dark-mode-toggle {
  .sun {
    @apply hidden;
  }

  .moon {
    @apply block;
  }
}


.dark {
  #dark-mode-toggle .sun {
    @apply block;
  }

  #dark-mode-toggle .moon {
    @apply hidden;
  }
}


#lang-dropdown {
  @apply z-10 bg-white divide-y divide-stone-100 rounded-lg shadow w-44 dark:bg-stone-700 text-stone-700 dark:text-stone-200;

  h3 {
    @apply p-2 text-xs text-stone-500 dark:text-stone-400;
  }

  ul {
    @apply py-2 text-sm text-stone-700 dark:text-stone-200;
    li {
      a {
        @apply block px-4 py-2 hover:bg-stone-100 dark:hover:bg-stone-600 dark:hover:text-white text-lg;
      }

      small {
        @apply bg-stone-200 text-stone-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md dark:bg-stone-600 dark:text-stone-400;
      }
    }
  }

  & > :not([hidden]) ~ :not([hidden]) {
    @apply border-stone-200 dark:border-stone-500
  }
}

*:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.active-lang{
  @apply border-r-2 border-red-400;
}
